import React from 'react';
import {BrowserRouter} from 'react-router-dom'

import './assets/css/icons.css'
import './assets/css/metismenu.min.css'
import './assets/css/style.css'
import './assets/css/custom.css'

import Routes from './Routes'

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
