const cliKey = 'ctr_key_cliente'
const INITIAL_STATE = {
	cliente: (localStorage.getItem(cliKey) !== 'undefined') ? JSON.parse(localStorage.getItem(cliKey)) : [],
	isValid: (localStorage.getItem(cliKey) !== 'undefined') ? true : null,
	clientes: [],
	redirectError: false
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'SET_CLIENTES':
			return {
				...state,
				clientes: action.payload
			}
			break;
		case 'SET_CLIENTE':
			localStorage.setItem(cliKey, JSON.stringify(action.payload))
			return {
				...state, 
				cliente: action.payload
			}
			break;
		case 'CLIENTE_SET_VALID':
			return {
				...state,
				isValid: action.payload
			}
			break;
		case 'CLIENTE_LOGOUT':
			localStorage.removeItem(cliKey)
			return {
				...state,
				cliente: [],
				isValid: null
			}
			break;
		case 'CLIENTE_ERROR':
			return {...state, cliente: []}
			break;
		case 'SET_REDIRECT_ERROR':
			return {...state, redirectError: action.payload}
			break;
		default:
			return state
	}
}