import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
	return (
		<Route
	  		{...rest}
	    	render={props => (
	      	(authed && authed !== undefined)
	        	? <Component {...props} />
	        	: <Redirect to={`/${props.match.params['cliente']}/login`} />
	    	)}
	  	/>
	)
};

export default PrivateRoute;