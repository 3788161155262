import axios from 'axios'

export function getcampanhas(token, cliente) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${cliente}/campanhas`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'SET_CAMPANHAS', payload: resp.data.campanhas}
					])
				}
			})
	}
}

export function updatecarteirinha(token, cliente) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${cliente}/atualiza`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'SET_UPDATE', payload: false},
						{type: 'SET_CARTEIRINHA', payload: resp.data.carteirinha},
						{type: 'USER_FETCHED', payload: resp.data.user}
					])
				}
			})
	}
}

export function getcampanha(token, cliente, campanha) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${cliente}/campanha/${campanha}`, config)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{type: 'SET_CAMPANHA', payload: resp.data.campanha}
						])
					} else {
						dispatch([
							{type: 'SET_CAMPANHA', payload: false},
							{type: 'CAMPANHA_ERROR', payload: true}
						])
					}
				} else {
					dispatch([
						{type: 'SET_CAMPANHA', payload: false},
						{type: 'CAMPANHA_ERROR', payload: true}
					])
				}
			})
	}
}

export function registercampain(token, cliente, user_id, transporte_campanha_id, values)
{
	const config = {
		headers: { 
			Authorization: `Bearer ${token}`,
			"Content-Type": "multipart/form-data"
		}
	}

	if(values.documentos !== undefined){
		var documentos = Object.entries(values.documentos)
	} else {
		var documentos = []
	}

	console.log(values.cidade_id)
	console.log(values.instituicao_id)

	let formData = new FormData()
	formData.append('user_id', user_id)
	formData.append('transporte_campanha_id', transporte_campanha_id)
	formData.append('nr_identidade', (values.nr_identidade) !== undefined ? values.nr_identidade : "")
	formData.append('logradouro', (values.logradouro) !== undefined ? values.logradouro : "")
	formData.append('numero', (values.numero) !== undefined ? values.numero : "")
	formData.append('complemento', (values.complemento) !== undefined ? values.complemento : "")
	formData.append('bairro', (values.bairro) !== undefined ? values.bairro : "")
	if(values.cidade_id){
		if(values.cidade_id.id !== undefined){
			formData.append('cidade_id', values.cidade_id.id)
		} else {
			formData.append('cidade_id', values.cidade_id)
		}
	} else {
		formData.append('cidade_id', "")
	}
	formData.append('telefone', (values.telefone) !== undefined ? values.telefone : "")
	formData.append('celular', (values.celular) !== undefined ? values.celular : "")
	formData.append('nome_mae', (values.nome_mae) !== undefined ? values.nome_mae : "")
	formData.append('nome_pai', (values.nome_pai) !== undefined ? values.nome_pai : "")
	formData.append('trabalha', (values.trabalha) !== undefined ? values.trabalha : "")
	formData.append('nome_empresa', (values.nome_empresa) !== undefined ? values.nome_empresa : "")
	formData.append('qtos_residem', (values.qtos_residem) !== undefined ? values.qtos_residem : "")
	formData.append('renda_familiar', (values.renda_familiar) !== undefined ? values.renda_familiar : "")
	if(values.instituicao_id){
		if(values.instituicao_id.id !== undefined){
			formData.append('instituicao_id', values.instituicao_id.id)
		} else {
			formData.append('instituicao_id', values.instituicao_id)
		}
	} else {
		formData.append('instituicao_id', "")
	}
	formData.append('turno', (values.turno) !== undefined ? values.turno : "")
	formData.append('tipo_curso', (values.tipo_curso) !== undefined ? values.tipo_curso : "")
	formData.append('curso', (values.curso) !== undefined ? values.curso : "")
	formData.append('serie', (values.serie) !== undefined ? values.serie : "")
	formData.append('inicio_curso', (values.inicio_curso) !== undefined ? values.inicio_curso : "")
	formData.append('final_curso', (values.final_curso) !== undefined ? values.final_curso : "")
	formData.append('hr_entrada', (values.hr_entrada) !== undefined ? values.hr_entrada : "")
	formData.append('hr_saida', (values.hr_saida) !== undefined ? values.hr_saida : "")
	formData.append('qtos_trabalham', (values.qtos_trabalham) !== undefined ? values.qtos_trabalham : "")
	formData.append('action', values.action)

	if(values.avatar !== undefined){
		formData.append('avatar', values.avatar[0], 'avatar')
	}

	for(var i = 0; i < documentos.length; i++){
		formData.append('documentos[]', documentos[i][0])
		formData.append(documentos[i][0], documentos[i][1][0], documentos[i][0])
	}

	return dispatch => {
		dispatch([
			{type: 'SET_SEND_TEXT', payload: "<i class='fas fa-spinner fa-spin'></i> Enviando..."},
			{type: 'REGISTER_ERROR', payload: ""}
		])

		axios.post(`${process.env.REACT_APP_URL}carteirinha/${cliente}/campanha/${transporte_campanha_id}/register`, formData, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'SET_SEND_TEXT', payload: "Enviar para Análise"},
					])
					if(resp.data.success){
						dispatch([
							{type: 'REGISTER_ERROR', payload: "<div class='alert alert-success my-2'>O cadastro foi realizado com sucesso. Após análise do cadastro e documentos você será notificado do andamento da solicitação.</div><div class='mt-2 text-align'><a href='/"+cliente+"' class='btn btn-primary'>Voltar a página inicial</a></div>"},
							{type: 'SET_CARTEIRINHA', payload: resp.data.carteirinha},
							{type: 'USER_FETCHED', payload: resp.data.user}
						])
					} else {
						dispatch([
							{type: 'REGISTER_ERROR', payload: resp.data.message}
						])
					}
				} else {
					dispatch([
						{type: 'SET_SEND_TEXT', payload: "Enviar para Análise"},
						{type: 'REGISTER_ERROR', payload: resp.data.message}
					])
				}
			}).catch(e => {
				var error = ""
				
				if(e.response.status == 422){
					let errors = Object.entries(e.response.data.errors)
					errors.forEach(function(item, index){
						error += '<p class="mb-0">'+item[1]+'</p>'
					})
				} else {
					error = e.response.data.message
				}

				dispatch([
					{type: 'SET_SEND_TEXT', payload: "Enviar para Análise"},
					{type: 'REGISTER_ERROR', payload: "<div class='alert alert-danger mt-2 mb-2'>"+error+"</div>"}
				])
			})
	}
}

export function getDocHistory(token, cliente, documento) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${cliente}/documentos/${documento}`, config)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{type: 'SET_DOC_HISTORY', payload: resp.data.historico}
						])
					} else {
						dispatch([
							{type: 'SET_DOC_HISTORY', payload: []}
						])
					}
				} else {
					dispatch([
						{type: 'SET_DOC_HISTORY', payload: []}
					])
				}
			})
	}
}