import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import main from './helpers/reducers/main'
import cliente from './helpers/reducers/cliente'
import user from './helpers/reducers/user'
import carteirinha from './helpers/reducers/carteirinha'
import campanhas from './helpers/reducers/campanhas'

const rootReducer = combineReducers({
	form: formReducer,
	main: main,
	cliente: cliente,
	profile: user,
	carteirinha: carteirinha,
	campanhas: campanhas
})

export default rootReducer