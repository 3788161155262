import React, {Component} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import PrivateRoute from './helpers/PrivateRoute';

import {login as Login, logout as Logout} from './templates/auth/login'
import {register as Register} from './templates/auth/register'
import {home as Home} from './templates/main/home'
import {campanhas as Campanhas} from './templates/campanhas/campanhas'
import {campanha as CampanhaDetail} from './templates/campanhas/campanha'
import {error as ErrorPage} from './templates/main/error'
import {clientes as Clientes} from './templates/main/clientes'

class Routes extends Component {
	render(){
		return(
			<Switch>
				<Route exact path="/:cliente/login" component={Login} />
				<Route exact path="/:cliente/logout" component={Logout} />
				<Route exact path="/:cliente/registrar" component={Register} />
				<PrivateRoute exact path="/:cliente" component={Home} authed={this.props.userData} />
				<PrivateRoute exact path="/:cliente/campanhas" component={Campanhas} authed={this.props.userData} />
				<PrivateRoute exact path="/:cliente/campanha/:id" component={CampanhaDetail} authed={this.props.userData} />
				<Route exact path="/" component={Clientes} />

				<Route component={ErrorPage} />
			</Switch>
		)
	}
}

const mapStateToProps = state => ({ userData: state.profile.userData });
export default connect(mapStateToProps)(Routes);