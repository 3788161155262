import React from 'react'
import axios from 'axios'

import { connect } from 'react-redux'

function rendermenu(props){

    return (
        <div className="navbar-custom-menu">
            <div className="container-fluid">
                <div id="navigation" className={props.estadoMenu}>
                    <ul className="navigation-menu list-unstyled text-center">
                        <li>
                            <a href={`/${props.cliente}/`}>
                                <i className="mdi mdi-monitor-dashboard"></i>Início
                            </a>
                        </li>
                        <li>
                            <a href={`/${props.cliente}/campanhas/`}>
                                <i className="mdi mdi-bus-school"></i>Campanhas
                            </a>
                        </li>
                        <li className="d-block d-xl-none">
                            <a href={`/${props.cliente}/logout`} className="">
                                <i className="mdi mdi-power"></i>Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userData: state.profile.userData,
    clienteData: state.cliente.cliente,
    estadoMenu: state.main.lineClass
})

const conMenu = connect(mapStateToProps)(rendermenu)
export default conMenu