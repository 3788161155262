const INITIAL_STATE = {
	campanhas: false,
	campanha: false,
	sendText: 'Enviar para Análise',
	registerError: '',
	modalShow: false,
	loadDocs: true,
	docHistory: []
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'SET_CAMPANHAS':
			return {
				...state, 
				campanhas: action.payload
			}
			break;
		case 'SET_CAMPANHA':
			return {
				...state, 
				campanha: action.payload
			}
			break;
		case 'SET_SEND_TEXT':
			return {
				...state,
				sendText: action.payload
			}
			break;
		case 'REGISTER_ERROR':
			return {
				...state,
				registerError: action.payload
			}
			break;
		case 'OPEN_MODAL':
			return {
				...state,
				modalShow: action.payload
			}
			break;
		case 'SET_LOAD_DOCS':
			return {
				...state,
				loadDocs: action.payload
			}
			break;
		case 'SET_DOC_HISTORY':
			return {
				...state,
				docHistory: action.payload
			}
			break;
		default:
			return state
	}
}