import React from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Menu from '../common/menu'
import Footer from '../common/footer'
import Loading from '../common/loading'

import {clientes} from '../../helpers/actions/infos'

function handlePrefeitura(prefeitura){
	if(prefeitura !== "0"){
		window.location.assign("/"+prefeitura)	
	}
}

function Clientes(props)
{
	const breadcrumb = ['Painel', 'Selecione a prefeitura']

	if(props.clienteData){
		return (
			<Redirect to={`/${props.clienteData.slug}`} />
		)
	} else {
		if(props.clientesData.length > 0){
			return (
				<div>
					<div className="page-wrapper">
						<Header titulo={`Sistema de Carteirinha`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
						<div className="page-content mt-4">
			                <div className="container-fluid"> 
			                    <div className="row">
			                        <div className="col-md-6 mx-auto">
			                            <div className="card">
			                                <div className="card-body">
			                                    <h4 className="text-primary">Selecione a prefeitura de sua cidade</h4><br />
			                                    <div className="form-group">
			                                    	<select className="form-control" onChange={(e) => console.log(e)}>
			                                    		<option value="0">Selecione uma cidade</option>
			                                    	</select>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </div>

			                </div>
			            </div>
					</div>
				</div>
			)
		} else {
			if(navigator.onLine){
				const {clientes} = props

				setTimeout(function(){
					clientes()
				}, 1500)

				return (
					<Loading />
				)
			} else {
				return (
					<div>
						<div className="page-wrapper">
							<Header titulo={`Sistema de Carteirinha`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
							<div className="page-content mt-4">
				                <div className="container-fluid"> 
				                    <div className="row">
				                        <div className="col-md-6 mx-auto">
				                            <div className="card">
				                                <div className="card-body">
				                                    <h4 className="text-primary">Você está offline</h4><br />
				                                    <p>Você precisa estar online para iniciar o sistema</p>
				                                </div>
				                            </div>
				                        </div>
				                    </div>

				                </div>
				            </div>
						</div>
					</div>	
				)
			}
		}
	}
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid,
	clientesData: state.cliente.clientes
})
const mapDispatchToProps = dispatch => bindActionCreators({
	clientes
}, dispatch)

const conClientes = connect(mapStateToProps, mapDispatchToProps)(Clientes)

export {conClientes as clientes}