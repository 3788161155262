import React from 'react'
import {Helmet} from "react-helmet"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { openMenu } from '../../helpers/actions/infos'

const Breadcrumb = (breadcrumb) => {
	return breadcrumb.map((bread, index) => {
		if(index == (breadcrumb.length - 1)){
			return (
				<li key={index} className="breadcrumb-item active">{bread}</li>
			)
		} else {
			return (
				<li key={index} className="breadcrumb-item"><a href="javascript:void(0);">{bread}</a></li>
			)
		}
	})
}

function header(props){
    const { openMenu } = props

    return(
		<>
        {props.clienteData && props.clienteData.length > 0 ? (
            <Helmet>
                <title>{props.clienteData.nome} - {props.titulo}</title>
            </Helmet>
        ) : (
            <Helmet>
                <title>{props.titulo}</title>
            </Helmet>
        )}
		<div className="topbar">
             
             <nav className="navbar-custom">

                <div className="topbar-left">
                    {props.clienteData ? (
                        <a href={`/${props.clienteData.slug}`} className="logo">
                            <span>
                                <img src={process.env.REACT_APP_UPLOADS + props.clienteData.logo_prefeitura} alt="logo-small" className="logo-sm" />
                            </span>
                            <h2 className="ml-2 d-inline-block" style={{ fontSize: "16px"}}>{ props.clienteData.nome }</h2>
                        </a>
                    ) : (
                        <a href="/" className="logo">
                            <h2 className="ml-2 d-inline-block" style={{ fontSize: "16px"}}>Sistema de Carteirinha</h2>
                        </a>
                    )}
                </div>
    
                <ul className="list-unstyled topbar-nav float-right mb-0">

                    <li className="dropdown d-none">
                        <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown" href="#" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <i className="mdi mdi-bell-outline nav-icon"></i>
                            <span className="badge badge-danger badge-pill noti-icon-badge">2</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                            
                            <h6 className="dropdown-item-text">
                                Notifications (258)
                            </h6>
                            <div className="slimscroll notification-list">
                                
                                <a href="javascript:void(0);" className="dropdown-item notify-item active">
                                    <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                                    <p className="notify-details">Your order is placed<small className="text-muted">Dummy text of the printing and typesetting industry.</small></p>
                                </a>
                            </div>
                            <a href="javascript:void(0);" className="dropdown-item text-center text-primary">
                                View all <i className="fi-arrow-right"></i>
                            </a>
                        </div>
                    </li>

                    <li className="menu-item">
                        
                        <a className={`navbar-toggle nav-link ${props.estadoMenu}`} id="mobileToggle" onClick={() => {openMenu(props.estadoMenu)}}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        
                    </li>    
                </ul>


            </nav>
            
        </div>
        
        <div className="page-wrapper-img">
            <div className="page-wrapper-img-inner">
                <div className="sidebar-user media">
                    {props.userData ? (
                        <>
                            <img src={process.env.REACT_APP_UPLOADS + props.userData.avatar} alt="user" className="rounded-circle img-thumbnail mb-1" />
                            <span className="online-icon"><i className="mdi mdi-record text-success"></i></span>
                            <div className="media-body">
                                <h5 className="text-light">{props.userData.name} </h5>
                                <ul className="list-unstyled list-inline mb-0 mt-2">
                                    <li className="list-inline-item">
                                        <a href={`/${props.cliente}/logout`} className=""><i className="mdi mdi-power text-light"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            
                            <h4 className="page-title mb-0 mb-xl-2">
                            	<i className={`${props.icone} mr-2`}></i>
                            	{props.titulo}
                            </h4>  
                            <div className="">
                                <ol className="breadcrumb">
                            	{Breadcrumb(props.breadcrumb)}    
                                </ol>
                            </div>                                      
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        </>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	clienteData: state.cliente.cliente,
    estadoMenu: state.main.lineClass
})

const mapDispatchToProps = dispatch => bindActionCreators({
    openMenu
}, dispatch)

const conHeader = connect(mapStateToProps, mapDispatchToProps)(header)
export {conHeader as Header}