import React from 'react'

const Loading = () => {
	return (
		<div className="row py-4">
			<div className="col-12 d-flex justify-content-center">
				<div className="d-flex align-items-center"> 
					<div className="loader">Loading...</div>
				</div>
			</div>
		</div>
	)
}

export default Loading