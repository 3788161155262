const carteirinhaKey = 'ctr_key_carteirinha'
const INITIAL_STATE = {
	carteirinhaData: (localStorage.getItem(carteirinhaKey) !== 'undefined') ? JSON.parse(localStorage.getItem(carteirinhaKey)) : [],
	update: true
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'SET_CARTEIRINHA':
			localStorage.setItem(carteirinhaKey, JSON.stringify(action.payload))
			return {
				...state, 
				carteirinhaData: action.payload
			}
			break;
		case 'CARTEIRINHA_LOGOUT':
			localStorage.removeItem(carteirinhaKey)
			return {
				...state, 
				carteirinhaData: []
			}
			break;
		case 'SET_UPDATE':
			return {
				...state,
				update: action.payload
			}
			break;
		default:
			return state
	}
}