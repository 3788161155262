import React from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import axios from 'axios'
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Menu from '../common/menu'
import Footer from '../common/footer'

import Loading from '../common/loading'
import RenderCampanhas from '../common/renderCampanhas'

import {getcampanhas} from '../../helpers/actions/campanhas'

function CampanhasList(props){
	if(props.campanhas){
		if(props.campanhas.length > 0){
			return(
				<div className="row">
					<RenderCampanhas {...props} />
				</div>	
			)
		} else {
			return(
				<>
					Não temos campanhas para exibir
				</>	
			)
		}
	} else {
		const {getcampanhas} = props

		setTimeout(function(){
			getcampanhas(props.userToken, props.match.params['cliente'])
		}, 1500)

		return(
			<Loading />
		)
	}
}

function Campanhas(props){
	const breadcrumb = [props.clienteData.nome, 'Painel', 'Início']

	return (
		<div>
			<Header cliente={props.match.params['cliente']} titulo={`Bem-vindo, ${props.userData.name}`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu cliente={props.match.params['cliente']} />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    		{CampanhasList(props)}
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={`Sistema de Carteirinha`} />
	            </div>
	        </div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid,
	carteirinhaData: state.carteirinha.carteirinhaData,
	campanhas: state.campanhas.campanhas
})
const mapDispatchToProps = dispatch => bindActionCreators({
	getcampanhas
}, dispatch)

const conCampanha = connect(mapStateToProps, mapDispatchToProps)(Campanhas)

export {conCampanha as campanhas}