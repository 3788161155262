const user_key = 'ctr_edutec_user'
const user_token = 'ctr_edutec_user_token'

const INITIAL_STATE = {
	userData: (localStorage.getItem(user_key) !== 'undefined') ? JSON.parse(localStorage.getItem(user_key)) : [],
	validToken: false,
	token: (localStorage.getItem(user_token) !== 'undefined') ? localStorage.getItem(user_token) : '',
	errorUser: ""
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'USER_TOKEN_VALIDATED':
			if(action.payload){
				return {...state, validToken: true, errorUser: ''}
			} else {
				localStorage.removeItem(user_key)
				localStorage.removeItem(user_token)
				return {...state, token: "", validToken: false, userData: null, errorUser: ''}
			}
			break;
		case 'USER_FETCH_TOKEN':
			localStorage.setItem(user_token, action.payload)
			return {...state, token: action.payload, validToken: true, errorUser: ''}
			break;
		case 'USER_FETCHED':
			localStorage.setItem(user_key, JSON.stringify(action.payload))
			return {...state, userData: action.payload, validToken: true, errorUser: ''}
			break;
		case 'USER_SET_ERROR':
			return {...state, errorUser: action.payload}
			break;
		default:
			return state
	}
}