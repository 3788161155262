import React from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import axios from 'axios'

const RenderCampanhas = props =>{
        return (
	<>
	{props.campanhas.map((campanha, index) => {
		return (
			<div key={index} className="col-12 col-lg-4">
				<div className="card text-center">
					<div className="card-body">
						<div className="blog-card mb-3">
							<h4 className="mb-2">
                        	{campanha.name}
                        </h4>
                        <div className="meta-box">
                        	<p className="pb-0 text-muted">
                        		Carteirinha válida de {campanha.formated_validade_inicio} à {campanha.formated_validade_fim}
                        	</p>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html:campanha.description }} />
                        <div className="mt-2">
                        	<a href={`/${props.match.params['cliente']}/campanha/${campanha.id}`} className="btn btn-primary btn-block">
                        		{props.carteirinhaData ? (
                        			<>
                        			Ver detalhes da campanha
                        			</>
                        		) : (
                        			<>
                        			Cadastrar para essa carteirinha
                        			</>
                        		)}
                        	</a>
                        </div>
						</div>
					</div>
				</div>
			</div>
		)
	})}
	</>
        )
}

export default RenderCampanhas