import React from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Menu from '../common/menu'
import Footer from '../common/footer'

import Img from '../../assets/images/error.svg'

function Error(props)
{
	const breadcrumb = ['Painel', 'Página não encontrada']

	return (
		<div>
			<div className="page-wrapper">
				<Header titulo="Página não encontrada" icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />
				<div className="page-content mt-4">
	                <div className="container-fluid"> 
	                    <div className="row">
	                        <div className="col-md-6 mx-auto">
	                            <div className="card">
	                                <div className="card-body">
	                                    <div className="row">
	                                        <div className="col-md-5 p-0 align-self-center">
	                                            <img src={Img} alt="" className="img-fluid" />
	                                        </div>
	                                        <div className="col-md-7">
	                                            <div className="error-content text-center">
	                                                <h1 className="">404!</h1>
	                                                <h4 className="text-primary">A página procura não foi localizada</h4><br />
	                                                {props.clienteData ? (
	                                                	<a className="btn btn-primary mb-5 waves-effect waves-light" href={`/${props.clienteData.slug}`}>Retornar ao Início</a>
	                                                ) : (
	                                                	<a className="btn btn-primary mb-5 waves-effect waves-light" href="/">Retornar ao Início</a>
	                                                )}
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>

	                </div>
	            </div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	clienteValido: state.cliente.isValid
})
const mapDispatchToProps = dispatch => bindActionCreators({
	
}, dispatch)

const conError = connect(mapStateToProps, mapDispatchToProps)(Error)

export {conError as error}