import axios from 'axios'

export function clientes() {
	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/clientes`)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{type: 'SET_CLIENTES', payload: resp.data.clientes},
						])
					} else {
						dispatch([
							{type: 'CLIENTE_LOGOUT', payload: null}
						])	
					}
				} else {
					dispatch([
						{type: 'CLIENTE_LOGOUT', payload: null}
					])
				}
			})
	}
}

export function infos(cliente = "") {
	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}carteirinha/${cliente}/infos`)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{type: 'SET_CLIENTE', payload: resp.data.cliente},
							{type: 'CLIENTE_SET_VALID', payload: true}
						])
					} else {
						dispatch([
							{type: 'SET_REDIRECT_ERROR', payload: true},
							{type: 'CLIENTE_LOGOUT', payload: null}
						])	
					}
				} else {
					dispatch([
						{type: 'SET_REDIRECT_ERROR', payload: true},
						{type: 'CLIENTE_LOGOUT', payload: null}
					])
				}
			})
	}
}

export function openMenu(estado) {
	if(estado === ''){
		var newEstado = 'open'
	} else {
		var newEstado = ''
	}

	return dispatch => {
		dispatch([
			{type: 'MENU_OPEN', payload: newEstado}
		])
	}
}

export function openModal(modal) {
	return dispatch => {
		dispatch([
			{type: 'SET_DOC_HISTORY', payload: []},
			{ type: 'OPEN_MODAL', payload: modal }
		])
	}
}