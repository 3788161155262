import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'

import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'

import { registerServiceWorker } from './serviceWorker'

import reducers from './Reducers'

registerServiceWorker();

const store = applyMiddleware(thunk, multi, promise)(createStore)(reducers)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
		<App />
	</Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
