const INITIAL_STATE = {
	loading: false,
	lineClass: ''
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'LOADING':
			return {
				...state,
				loading: action.payload
			}
			break;
		case 'MENU_OPEN':
			return {
				...state,
				lineClass: action.payload
			}
			break;
		default:
			return state
	}
}